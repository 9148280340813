import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import { Tabs, Tab, List, Image, Icon } from 'vant'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Loading: Loading,
        Share: Share,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [List.name]: List,
        [Image.name]: Image,
        [Icon.name]: Icon
    },
    data () {
        return {
            loadingShow: false,
            applyFinishedShow: false,
            historyFinishedShow: false,
            active: 'apply',
            merchantCode: '',
            wallet: {},
            applyList: [],
            historyList: [],
            applyPage: { current: 0, size: 10, last: 0 },
            historyPage: { current: 0, size: 10, last: 0 }
        }
    },
    mounted () {
        var query = this.$route.query
        this.merchantCode = query.merchantCode
        this.retrieveWallet()
        this.$refs.share.default('main')
    },
    methods: {
        activeChange (val) {
            if (val === 'apply') {
                this.applyList = []
                this.applyPage.current = 0
                this.applyPage.last = 0
                this.retrieveApplyList()
            } else if (val === 'history') {
                this.historyList = []
                this.historyPage.current = 0
                this.historyPage.last = 0
                this.retrieveHistoryList()
            }
        },
        async retrieveWallet () {
            var pd = { merchantCode: this.merchantCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWallet', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        loadApplyTaskList () {
            if (this.applyPage.last >= this.applyPage.current) {
                this.retrieveApplyList()
            } else {
                this.applyFinishedShow = true
            }
        },
        loadHistoryTaskList () {
            if (this.applyPage.last >= this.applyPage.current) {
                this.retrieveHistoryList()
            } else {
                this.historyFinishedShow = true
            }
        },
        async retrieveApplyList () {
            var pd = { merchantCode: this.merchantCode, current: this.applyPage.current, size: this.applyPage.size }
            this.applyPage.current = this.applyPage.current + 1
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWExtractApplyList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.applyList = this.applyList.concat(res.data)
                this.applyPage.last = res.page.last
            }
        },
        async retrieveHistoryList () {
            var pd = { merchantCode: this.merchantCode, current: this.applyPage.current, size: this.applyPage.size }
            this.historyPage.current = this.historyPage.current + 1
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWExtractHistoryList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.historyList = this.historyList.concat(res.data)
                this.historyPage.last = res.page.last
            }
        },
        createExtract () {
            this.$router.push({ path: '/mde/wallet/busExtractCreate', query: { merchantCode: this.merchantCode } })
        },
        exchange () {
            this.$router.push({ path: '/mde/wallet/busExtractExchange', query: { merchantCode: this.merchantCode } })
        },
        deleteExtract (id) {
            var that = this
            this.$dialog.confirm({
                title: '删除确认',
                message: '确认要删除提现申请吗？'
            })
                .then(() => {
                    // on confirm
                    that.deleteExecute(id)
                })
                .catch(() => {
                    // on cancel
                })
        },
        async deleteExecute (id) {
            var pd = { id: id }
            var that = this
            this.loadingShow = true
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/deleteWalletExtract', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示',
                    message: '删除成功'
                }).then(() => {
                    // on close
                    that.applyList = []
                    that.applyPage.current = 0
                    that.applyPage.last = 0
                    that.retrieveApplyList()
                    this.retrieveWallet()
                })
            }
        },
        back () {
            this.$router.go(-1)
        }
    }
}
