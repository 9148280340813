<template>
  <div class="page">
    <van-nav-bar title="收益提现" left-arrow @click-left="back()" />
    <div class="top">
      <van-row>
        <van-col :span="12" class="brs"
          ><div class="text">可兑换金豆</div>
          <div class="amount">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-beans"></use>
            </svg>
            {{ wallet.beanBalance }}个
          </div>
          <div class="convert color-s1">(可兑换人民币1元)</div>
          <div class="button">
            <van-button
              size="small"
              :color="COLOR_S1"
              icon="exchange"
              class="btn"
              disabled
              @click="exchange()"
              >兑换现金</van-button
            >
          </div>
        </van-col>
        <van-col :span="12">
          <div class="text">可提现金额</div>
          <div class="amount">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-money"></use>
            </svg>
            {{ wallet.coinBalance }}元
          </div>
          <div class="tips color-s1">申请后2个工作日内完成审核</div>
          <div class="button">
            <van-button
              size="small"
              :color="COLOR_M"
              icon="plus"
              class="btn"
              @click="createExtract()"
              >立即提现</van-button
            >
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="record">
      <van-tabs
        :active="active"
        :color="COLOR_M"
        border="true"
        @change="activeChange"
      >
        <van-tab title="待审核" name="apply"
          ><van-list
            :finished="applyFinishedShow"
            finished-text="没有更多了"
            @load="loadApplyTaskList"
          >
            <template #default>
              <van-row class="item" v-for="apply in applyList" :key="apply.id">
                <van-col span="4">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-income"></use>
                  </svg>
                </van-col>
                <van-col span="14"
                  ><div class="desc">提现金额:{{ apply.amount }}元</div>
                  <div class="time">{{ apply.time }}</div>
                </van-col>
                <van-col span="6">
                  <div class="button">
                    <van-button
                      icon="delete-o"
                      :color="COLOR_S2"
                      size="mini"
                      @click="deleteExtract(apply.id)"
                      >删除</van-button
                    >
                  </div>
                </van-col>
              </van-row>
              <van-row v-if="applyList.length <= 0">
                <van-col span="24" class="no-record">
                  <van-image
                    width="103"
                    height="103"
                    :src="
                      require('../../../../assets/images/home/no-record.png')
                    "
                  ></van-image>
                </van-col>
              </van-row> </template
          ></van-list>
        </van-tab>
        <van-tab title="历史记录" name="history"
          ><van-list
            :finished="historyFinishedShow"
            finished-text="没有更多了"
            @load="loadHistoryTaskList"
          >
            <template #default>
              <van-row
                class="item"
                v-for="history in historyList"
                :key="history.id"
              >
                <van-col span="4">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-income"></use>
                  </svg>
                </van-col>
                <van-col span="14"
                  ><div class="desc">提现金额:{{ history.amount }}元</div>
                  <div class="time">{{ history.time }}</div>
                </van-col>
                <van-col span="6">
                  <span class="state color-m" v-if="history.result === 'SUC'"
                    >成功</span
                  ><span class="state color-s1" v-if="history.result === 'FAL'"
                    >失败</span
                  >
                </van-col>
              </van-row>
              <van-row v-if="historyList.length <= 0">
                <van-col span="24" class="no-record">
                  <van-image
                    width="103"
                    height="103"
                    :src="
                      require('../../../../assets/images/home/no-record.png')
                    "
                  ></van-image>
                </van-col>
              </van-row> </template></van-list
        ></van-tab>
      </van-tabs>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Business from './Business.js'
export default {
  ...Business
}
</script>
<style lang="less" scoped>
@import './Business.less';
</style>
